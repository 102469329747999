var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.globals.contact.socialNetworks.facebook)?_c('a',{staticClass:"icon-social-link",attrs:{"href":_vm.globals.contact.socialNetworks.facebook,"alt":"facebook","target":"_blank"}},[_vm._m(0)]):_vm._e(),(_vm.globals.contact.socialNetworks.twitter)?_c('a',{staticClass:"icon-social-link",attrs:{"href":_vm.globals.contact.socialNetworks.twitter,"alt":"twitter","target":"_blank"}},[_vm._m(1)]):_vm._e(),(_vm.globals.contact.socialNetworks.linkedin)?_c('a',{staticClass:"icon-social-link",attrs:{"href":_vm.globals.contact.socialNetworks.linkedin,"alt":"linkedin","target":"_blank"}},[_vm._m(2)]):_vm._e(),(_vm.globals.contact.socialNetworks.tiktok)?_c('a',{staticClass:"icon-social-link",attrs:{"href":_vm.globals.contact.socialNetworks.tiktok,"alt":"tiktok","target":"_blank"}},[_vm._m(3)]):_vm._e(),(_vm.globals.contact.socialNetworks.instagram)?_c('a',{staticClass:"icon-social-link",attrs:{"href":_vm.globals.contact.socialNetworks.instagram,"alt":"instagram","target":"_blank"}},[_vm._m(4)]):_vm._e(),(_vm.globals.contact.socialNetworks.vimeo)?_c('a',{staticClass:"icon-social-link",attrs:{"href":_vm.globals.contact.socialNetworks.vimeo,"alt":"vimeo","target":"_blank"}},[_vm._m(5)]):_vm._e(),(_vm.globals.contact.socialNetworks.youtube)?_c('a',{staticClass:"icon-social-link",attrs:{"href":_vm.globals.contact.socialNetworks.youtube,"alt":"youtube","target":"_blank"}},[_vm._m(6)]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"fa-stack"},[_c('i',{staticClass:"fa-solid fa-circle fa-stack-2x"}),_c('i',{staticClass:"fa-brands fa-facebook-f fa-stack-1x fa-inverse"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"fa-stack"},[_c('i',{staticClass:"fa-solid fa-circle fa-stack-2x"}),_c('i',{staticClass:"fa-brands fa-twitter fa-stack-1x fa-inverse"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"fa-stack"},[_c('i',{staticClass:"fa-solid fa-circle fa-stack-2x"}),_c('i',{staticClass:"fa-brands fa-linkedin-in fa-stack-1x fa-inverse"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"fa-stack"},[_c('i',{staticClass:"fa-solid fa-circle fa-stack-2x"}),_c('i',{staticClass:"fa-brands fa-tiktok fa-stack-1x fa-inverse"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"fa-stack"},[_c('i',{staticClass:"fa-solid fa-circle fa-stack-2x"}),_c('i',{staticClass:"fa-brands fa-instagram fa-stack-1x fa-inverse"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"fa-stack"},[_c('i',{staticClass:"fa-solid fa-circle fa-stack-2x"}),_c('i',{staticClass:"fa-brands fa-vimeo-v fa-stack-1x fa-inverse"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"fa-stack"},[_c('i',{staticClass:"fa-solid fa-circle fa-stack-2x"}),_c('i',{staticClass:"fa-brands fa-youtube fa-stack-1x fa-inverse"})])
}]

export { render, staticRenderFns }